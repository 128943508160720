/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import FooterComponent from './components/footer.component'
import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    //Component
    new NavbarComponent()
    new FooterComponent()

    //Front page only
    if (IRISCollectionCustomer.isFrontPage) {
        if (IRISCollectionCustomer.isEcomodeActive) {
            import(
                '@scripts/services/home-ecomode.service' /* webpackChunkName: "scripts/home-ecomode.service" */
            ).then(({ default: HomeEcomodeService }) => {
                new HomeEcomodeService()
            })
        } else {
            import(
                '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
            ).then(({ default: HomeService }) => {
                new HomeService()
            })
        }
    }

    //Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }
})

export default class FooterComponent {
    constructor() {
        FooterComponent.mapsSwitch()
    }

    static mapsSwitch() {
        const trigger = '[data-trigger="map-footer"]'
        const map = '[data-element="map-footer"]'

        $(trigger).on('click', () => {
            $(trigger).toggleClass('active')
            $(map).toggleClass('active')
        })
    }
}

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.select()
        NavbarComponent.menu()
        NavbarComponent.lang()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const body = $('body')
            const offset = 100 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                body.addClass('header-is-hidden')
            } else {
                body.removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (!body.hasClass('menu-open')) {
                if (scrollTop > offset) {
                    navbar.removeClass('navbar--ontop')
                    navbar.addClass('navbar--onscroll')

                    if (body.hasClass('is-seminar')) {
                        NavbarComponent.changeLogoColor('seminar-onscroll')
                    } else if (full === true && !body.hasClass('is-seminar')) {
                        NavbarComponent.changeLogoColor('classic')
                    }
                } else {
                    navbar.removeClass('navbar--onscroll')
                    navbar.addClass('navbar--ontop')
                    if (full === true) {
                        NavbarComponent.changeLogoColor('banner')
                    }
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic
        let logoSeminarOnscroll

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if ($('[data-logo="seminar"]').length > 0) {
            logoBanner = IRISCollectionCustomer.imageLogoBannerSeminar
            logoClassic = IRISCollectionCustomer.imageLogoSeminar
            logoSeminarOnscroll = IRISCollectionCustomer.imageLogoSeminar
        }

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        } else if (color === 'seminar-onscroll') {
            logo.attr('src', logoSeminarOnscroll)
        }
    }

    static burger() {
        const body = $('body')
        const menu = '[data-element="menu"]'
        const menuLv2 = '[data-element="menu-lv2"]'
        const navbar = $('[data-id="wpiris-navbar"]')
        const trigger = '[data-trigger="menu-toggle"]'
        const prev = '[data-trigger="menu-prev"]'
        const submenu = '[data-trigger="submenu"]'
        const ecomode = '[data-id="eco-bar"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            //Toggle class menu
            body.toggleClass('menu-open')

            //Change logo
            if (body.hasClass('menu-open')) {
                NavbarComponent.changeLogoColor('banner')
                body.removeClass('header-is-hidden')
            } else {
                if (navbar.hasClass('navbar--ontop') && body.hasClass('has-banner')) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    NavbarComponent.changeLogoColor('classic')
                }
            }

            if (window.matchMedia('(max-width: 1000px)').matches) {
                $(submenu).removeClass('active')
            }

            //Hide ecomode
            $(ecomode).addClass('eco-bar--hidden')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('active')
            $(menuLv2).removeClass('active')
            $(menu).removeClass('menu--lv2-active')
            setTimeout(() => {
                $(menu).css('z-index', '2')
            }, 500)
        })
    }

    static menu() {
        const body = $('body')
        const menu = '[data-element="menu"]'
        const menuLv1 = '[data-trigger="menu-lv1"]'
        const menuLv2 = '[data-element="menu-lv2"]'
        const menuLv1More = '[data-trigger="menu-lv1-more"]'
        const submenu = '[data-trigger="submenu"]'
        const close = '[data-trigger="menu-close"]'
        const navbar = $('[data-id="wpiris-navbar"]')

        $(menuLv1).on('click', function (e) {
            if (
                window.matchMedia('(min-width: 1001px)').matches &&
                $(this).hasClass('has-submenu')
            ) {
                e.preventDefault()
                const activeIndex = $(this).data('menu-lv1')

                //Class active lv1 item
                $(menuLv1).removeClass('active')
                $(this).addClass('active')

                //Class active lv2 item
                $(menuLv2).addClass('active')
                $(submenu).removeClass('active')
                $(`[data-submenu="${activeIndex}"]`).addClass('active')
            }
        })

        $(menuLv1More).on('click', function (e) {
            e.preventDefault()
            const activeIndex = $(this).data('menu-lv1')

            //Class active lv2 item
            $(menu).css('z-index', '4')
            $(menu).addClass('menu--lv2-active')
            $(menuLv2).addClass('active')
            $(submenu).removeClass('active')
            $(`[data-submenu="${activeIndex}"]`).addClass('active')
        })

        $(close).on('click', (e) => {
            e.preventDefault()

            //Class active menu item
            $(menuLv1).removeClass('active')
            $(menuLv2).removeClass('active')
            $(submenu).removeClass('active')
            $(menu).css('z-index', '2')
            $(menu).removeClass('menu--lv2-active')

            body.removeClass('menu-open')

            //Change logo
            if (navbar.hasClass('navbar--ontop') && body.hasClass('has-banner')) {
                NavbarComponent.changeLogoColor('banner')
            } else {
                NavbarComponent.changeLogoColor('classic')
            }
        })
    }

    static select() {
        const trigger = '[data-trigger="navbar-select"]'

        $(trigger).on('click', function () {
            $(this).toggleClass('select-open')
        })

        $(document).mouseup((e) => {
            if (!$(trigger).is(e.target) && $(trigger).has(e.target).length === 0) {
                $(trigger).removeClass('select-open')
            }
        })
    }

    static lang() {
        const switcher = '[data-trigger="lang-switcher"]'
        const list = '[data-id="lang-switcher-list"]'

        $(switcher).on('click', () => {
            $(list).toggleClass('lang-switch__list--open')
        })
    }
}
